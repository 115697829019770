<template>
  <div class="wrapper">
    <div class="content">
      <div class="warning">
        <p>开团后邀请好友参团，人数不足自动退款</p>
        <span class="backhome" @click="goHome"><i class="iconfont">&#xe695;</i>首页</span>
      </div>
      <div class="swiper">
        <swiper :pagination="true" class="mySwiper">
           <swiper-slide v-for="item in data.goodsModel.album" :key="item">
             <img :src="item.photoUrl" />
           </swiper-slide>
        </swiper>
          <!--<img v-if="data.goodsModel.album" :src="data.goodsModel.album[0].photoUrl"/>-->
          <span v-if="data.goodsModel.album">
            <img v-if="data.goodsModel.album.length<=0" src="../../images/default_goods.png"/>
          </span>
      </div>
      <div class="detail">
        <h3>{{data.groupModel.goodsName}}</h3>
        <div class="detail-list-01" style="margin-bottom: 0">
          <span class="hot-sell">热卖</span>
          <span class="share-btn" @click="share">分享</span>
          <div class="clear"></div>
        </div>
        <div class="detail-list-01" style="margin-top:0">
          <span v-if="data.groupModel.purchaseType==='0'">{{data.groupModel.joinNum}}人团 </span>
          <span v-if="data.groupModel.purchaseType==='0'">共{{data.groupModel.totalNum}}{{data.groupModel.unit}}</span>
          <span v-if="data.groupModel.purchaseType==='0'"> 每人{{data.groupModel.perNum}}{{data.groupModel.unit}}</span>
          <span v-if="data.groupModel.purchaseType==='1'">{{data.groupModel.totalNum}}{{data.groupModel.unit}}团</span>
        </div>
        <div class="detail-price-wrap">
          <span class="price-new">¥{{data.groupModel.goodsPrice}}/{{data.groupModel.unit}}</span>
        </div>
        <div class="detail-list-02">
          <span>已售{{data.goodsModel.sales}}份</span>
        </div>
        <div class="detail-list-03">
          <span v-for="item in data.shopService" :key="item.id">{{item.title}}</span>
          <span class='private_domain' v-if="data.groupModel.groupType==1">此拼团为私域拼团，只有邀请的好友可见</span>
        </div>
      </div>
      <div class="group-purchase-wrap">
        <div class="group-purchase-title">
            <h3>拼团</h3>
        </div>
        <div class="group-purchase-list" v-for="item in data.goingList" :key="item.id">
            <div class="group-purchase" v-if="data.groupModel.groupType==1">
                <div v-if="data.groupOriginatorId==item.originatorId">
                  <div class="group-purchase-l">
                    <img :src="item.avator?item.avator:require('../../images/default_avatar.png')"/>
                    <span>{{item.nickName}}</span>
                </div>
                <span class="onGroup" @click="()=>{handleGroup(false,item)}">去参团</span>
                <div :class="{'group-purchase-r':true, 'paddingt':item.endTime===''}">
                    <p v-if="item.purchaseType!='1'">还差<span>{{item.remainUserCount}}人</span>成团</p>
                    <p v-else>还差<span>{{item.remainWeightCount}}{{item.unit?item.unit:"份"}}</span>成团</p>
                    <p v-if="item.endTime!==''">剩余{{item.downTimeStr}}</p>
                </div>
                <div class="clear"></div>
                </div>
            </div>
            <div class="group-purchase" v-else>
                <div class="group-purchase-l">
                    <img :src="item.avator?item.avator:require('../../images/default_avatar.png')"/>
                    <span class="nickname">{{item.nickName}}</span>
                </div>
                <span class="onGroup" @click="()=>{handleGroup(false,item)}">去参团</span>
                <div :class="{'group-purchase-r':true, 'paddingt':item.endTime===''}">
                    <p v-if="item.purchaseType!='1'">还差<span>{{item.remainUserCount}}人</span>成团</p>
                    <p v-else>还差<span>{{item.remainWeightCount}}{{item.unit?item.unit:"份"}}</span>成团</p>
                    <p v-if="item.endTime!==''">剩余{{item.downTimeStr}}</p>
                </div>
                <div class="clear"></div>
            </div>
        </div>
      </div>
      <div class="detail-cnt-wrap">
        <h4 class="detail-cnt-title">商品详情</h4>
        <div class="detail-cnt" v-for="item in data.goodsAppContentList" :key="item">
          <img :src="item.fileUrl" />
          <text
            >{{item.content}}</text
          >
        </div>
      </div>
    </div>
    <div class="fixed-bom" @click="handleGroup(true,'')">
      <span>¥{{data.groupModel.goodsPrice}}</span>
      <span>发起拼单</span>
    </div>
    <div :class="{'mask': true, 'isShow': data.isShowCar === true}" @touchmove.prevent>
  </div>
  <div :class="{'fixed-car': true, 'isShow': data.isShowCar === true}">
    <div class="mygroup-info">
      <div class="mygroup-img">
        <img :src="data.groupModel.coverUrl" />
      </div>
      <div class="mygroup-info-r">
        <h4>{{data.groupModel.goodsName}}</h4>
        <div class="mygroup-info-list">
          <span v-if="data.groupModel.purchaseType==='0'">{{data.groupModel.joinNum}}人团 </span>
          <span v-if="data.groupModel.purchaseType==='0'">共{{data.groupModel.totalNum}}{{data.groupModel.unit}}</span>
          <span v-if="data.groupModel.purchaseType==='0'"> 每人{{data.groupModel.perNum}}{{data.groupModel.unit}}</span>
          <span v-if="data.groupModel.purchaseType==='1'">{{data.groupModel.totalNum}}{{data.groupModel.unit}}团</span>
        </div>
        <div class="mygroup-info-list">
          <span class="price-new">¥{{data.groupModel.goodsPrice}}/{{data.groupModel.unit}}</span>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="car-options">
      <span class="car-min" @click="()=>{changeCar(-1)}"  v-if="data.groupModel.purchaseType==='1'"></span>
      <input type="text" v-model="data.carNum" v-if="data.groupModel.purchaseType==='1'"/>
      <input type="text" v-model="data.carNum" disabled v-if="data.groupModel.purchaseType==='0'" />
      <span class="car-add" @click="()=>{changeCar(1)}" v-if="data.groupModel.purchaseType==='1'"></span>
      <div class="clear"></div>
    </div>
    <span class="confirm-btn" @click="()=>{confirmGroup(data.groupModel)}">确定</span>
    <span class="fixed-car-close" @click="handleColse"></span>
  </div>
  <div :class="{'mask2': true, 'mask2Show': data.maskshow===true}" @click="closeMask">
    <img src="../../images/share.png"/>
  </div>
</div>

</template>
<script>
import qs from 'qs'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { reactive, onMounted } from 'vue'
import { post, ajaxParam, config } from '../../units/request.js'
import { Toast } from 'vant'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { getCookie, removeCookie } from '../../units/tools.js'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.min.css'
import wx from 'weixin-js-sdk'
import SwiperCore, {
  Pagination
} from 'swiper/core'

// install Swiper modules
SwiperCore.use([Pagination])
export default {
  components: { Swiper, SwiperSlide },
  name: 'Detail',
  computed: {
    mybuyAmount () {
      return this.$store.state.buyAmount
    }
  },
  setup (props, context) {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    // const { pickUpModel } = toRefs(store.state)
    const data = reactive({
      groupModel: {},
      goodsModel: {},
      goodsAppContentList: [],
      shopService: [],
      carNum: 1,
      id: '',
      isShowCar: false,
      goingList: [],
      makeGroupId: '',
      groupOriginatorId: '-1',
      maskshow: false,
      userId: '',
      maxBuy: 1,
      dispatchType: 1
    })
    onMounted(() => {
      data.id = route.query.id
      if (getCookie('userId')) {
        data.userId = getCookie('userId')
      }
      if (route.query.originatorId) {
        data.groupOriginatorId = route.query.originatorId
        // data.userId = route.query.originatorId
      }
      removeCookie('originAddress')
      const getDetail = async () => {
        const param = {
          id: data.id,
          userId: data.userId
        }
        ajaxParam(param)
        const res = await post('v1/groupPurchase/info.json', qs.stringify(param)).then()
        data.groupModel = res.data.entity
        data.goodsModel = res.data.goods
        data.goingList = res.data.entity.goingList
        if (data.goingList) {
          for (var i = 0; i < data.goingList.length; i++) {
            getCountDown(data.goingList[i])
          }
        }
        data.goodsAppContentList = res.data.goodsAppContentList
        if (res.data.code === '200') {
          shopServiceRequest(res.data.entity.shopId)
        }
      }
      getDetail()
      const getCountDown = (obj) => {
        const timestamp = obj.endTime
        const count = (timestamp - Date.parse(new Date()))
        if (count <= 0) {
          return false
        }
        const intervalId = setInterval(function () {
          const t = (timestamp - Date.parse(new Date()))
          if (t <= 0) {
            clearInterval(intervalId)
          }
          let hour = Math.floor(t / 1000 / 60 / 60)
          let min = Math.floor(t / 1000 / 60 % 60)
          let sec = Math.floor(t / 1000 % 60)
          let countDownTime = ''
          if (hour < 10) {
            hour = '0' + hour
          }
          if (min < 10) {
            min = '0' + min
          }
          if (sec < 10) {
            sec = '0' + sec
          }
          if (hour > 24) {
            const day = Math.floor(hour / 24)
            const hours = hour % 24
            countDownTime = day + '天' + hours + '小时'
          } else {
            countDownTime = hour + ':' + min + ':' + sec
          }
          obj.downTimeStr = countDownTime
        }, 1000)
      }
      const shopServiceRequest = async (shopId) => {
        const param = {
          shopId: shopId
        }
        ajaxParam(param)
        const res = await post('v1/shopServiceDescription/list.json', qs.stringify(param))
        data.shopService = res.data.list
      }
    })
    const handleGroup = (isoriginator, item) => {
      data.carNum = 1
      if (!getCookie('isLogin')) {
        Toast('您还未登录，请先登录')
        setTimeout(res => {
          router.push({ path: '/login' })
        }, 1000)
        return
      }
      if (isoriginator === false) {
        if (item.downTimeStr === '00:00:00') {
          Toast('拼团结束啦！')
          return
        } else if (item.originatorId === getCookie('userId')) {
          Toast('您已经参团啦！')
          return
        }
        // 去参团
        if (item) {
          data.makeGroupId = item.id
          // data.groupModel.maxBuyNum = data.groupModel.maxBuyNum - item.
          if (item.purchaseType !== '1') {
            data.carNum = data.groupModel.perNum
            store.dispatch('changeAmount', data.carNum)
          } else {
            data.maxBuy = item.remainWeightCount
          }
          if (data.groupModel.groupType === '1') {
            // 私域拼团 获取自提点地址
            getOriginatorOrderAddress(item.id)
          }
        }
      }
      if (isoriginator === true) {
        if (data.groupModel.purchaseType === '1') {
          data.maxBuy = data.groupModel.maxBuyNum
        }
        if (data.groupModel.purchaseType === '0') {
          data.carNum = data.groupModel.perNum
          store.dispatch('changeAmount', data.carNum)
        }
        data.groupOriginatorId = '-1'
      }
      store.commit('getGroupId', data.makeGroupId)
      data.isShowCar = true
    }
    const getOriginatorOrderAddress = async (makeGroupId) => {
      const param = {
        makeGroupId: makeGroupId,
        groupOriginatorId: data.groupOriginatorId
      }
      ajaxParam(param)
      const res = await post('v1/groupPurchase/getOriginatorOrderAddress.json', qs.stringify(param))
      // 保存发起人的自提点
      data.dispatchType = res.data.dispatch_type
      store.commit('changeOriginAddress', res.data)
    }
    const handleColse = () => {
      data.isShowCar = false
    }
    const changeCar = (num) => {
      data.carNum += num
      if (data.carNum >= data.maxBuy) {
        Toast('最多买' + data.maxBuy + '个')
        data.carNum = data.maxBuy
        // this.$store.state.buyAmount = 88
        // 想改变数据 ，必须派发一个action
        // store.dispatch('changeAmount', data.carNum)
        // return false
      }
      // alert(data.carNum)
      if (data.carNum <= 0) {
        Toast('起购量1')
        data.carNum = 1
      }
      store.dispatch('changeAmount', data.carNum)
    }
    const confirmGroup = async (obj) => {
      const param = {
        id: data.id,
        userId: getCookie('userId') || ''
      }
      ajaxParam(param)
      const res = await post('v1/groupPurchase/userJiongoing.json', qs.stringify(param))
      if (res.data.code === '500') {
        Toast(res.data.msg)
        return
      }
      // 保存拼团商品
      store.dispatch('changeAmount', data.carNum)
      store.commit('addCar', obj)
      const orderRouteParam = {
        groupType: data.groupModel.groupType,
        originatorId: data.groupOriginatorId,
        dispatchType: data.dispatchType
      }
      store.commit('savaOrderRouteParam', orderRouteParam)
      router.push('/order')
    }
    const share = async () => {
      data.maskshow = true
      const url = window.location.href.split('#')[0]
      const param = {
        url: url,
        appId: config.appId,
        appSecret: config.appSecret
      }
      ajaxParam(param)
      const res = await post('v2/user/getWxShareSignature.json', qs.stringify(param))
      if (wx) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: config.appId, // 必填，公众号的唯一标识
          timestamp: res.data.timestamp + '', // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr + '', // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名，见附录1
          jsApiList: ['onMenuShareAppMessage'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(() => {
          // alert('ready')
          wx.onMenuShareAppMessage({
            title: data.groupModel.goodsName,
            desc: data.groupModel.goodsName,
            // link: window.location.href,
            link: encodeURI(url + '#/detail?id=' + data.groupModel.id),
            imgUrl: data.goodsModel.album[0] ? data.goodsModel.album[0].photoUrl : '../../images/login.png',
            type: '',
            success: (res) => {
              data.maskshow = false
              // alert('成功')
            },
            cancel: (res) => {
              // alert('取消')
              data.maskshow = false
            }
          })
        })
        wx.error(function (res) {
          // alert('err', res)
        })
      }
    }
    const closeMask = () => {
      data.maskshow = false
    }
    const goHome = () => {
      router.push('/')
    }
    return { data, share, handleGroup, changeCar, confirmGroup, handleColse, closeMask, goHome }
  }
}
</script>
<style lang="scss" scoped>
.content {
  padding-bottom:.6rem;
  .warning {
    background: #ffe8e6;
    padding: 0.05rem 0.08rem;
  }
  .warning p {
    background: url("../../images/warning.png") no-repeat left center;
    background-size: 0.16rem 0.16rem;
    line-height: 0.2rem;
    padding-left: 0.22rem;
    font-size: 0.13rem;
  }
  .warning .backhome{
    width: .48rem;
    height: .2rem;
    position:absolute;
    right: 0;
    top: .06rem;
    font-size: .13rem
  }
  .warning .backhome .iconfont{
    padding-right:.02rem;
    font-size: .14rem;
  }
  .swiper img{
      width: 100%;
      max-height: 3.5rem;
  }
  .detail {
    padding: 0.15rem 0 0.15rem 0.15rem;
    margin-bottom: 0.1rem;
    background: #fff;
  }
  .detail h3 {
    font-size: 0.16rem;
  }
  .detail-list-01 {
    margin: 0.1rem 0;
  }
  .hot-sell {
    float: left;
    background: #ff371e;
    border-radius: 10px;
    padding: 0 0.05rem;
    color: #fff;
    font-size: 0.16rem;
  }
  .share-btn {
    float: right;
    width: 0.4rem;
    height: 0.28rem;
    padding-left: 0.4rem;
    line-height: 0.28rem;
    background: #eee url("../../images/icon_share_h.png") no-repeat 0.15rem
      center;
    background-size: 0.15rem 0.15rem;
    border-radius: 0.15rem 0 0 0.15rem;
    font-size: 0.15rem;
  }
  .detail-list-02 {
    margin-top: 0.1rem;
    color: #aaa;
    font-size: 0.16rem;
  }
  .detail-list-03 {
    margin-top: 0.1rem;
    color: #aaa;
    font-size: 0.12rem;
    line-height: .2rem;
  }
  .detail-list-03 span {
    display: inline-block;
    background: url("../../images/icon_duigou.png") no-repeat left center;
    background-size: 0.14rem 0.14rem;
    padding-left: 0.2rem;
    margin-right: 0.1rem;
  }
  .detail-cnt-wrap {
    background: #fff;
    padding: 0.15rem;
  }
  .detail-cnt-title {
    padding-bottom: 0.2rem;
    font-size: 0.16rem;
  }
  .detail-cnt {
    line-height: 0.3rem;
  }
  .detail-cnt img {
    max-width: 100%;
    margin-bottom: 0.1rem;
  }
  .price-new {
    color: #ff371e;
  }
  .price-old {
    text-decoration: line-through;
    font-size: 0.16rem;
  }
}
.fixed-bom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.6rem;
  padding-top: 0.1rem;
  background: #ff371e;
  color: #fff;
  z-index: 9;
}
.fixed-bom span {
  display: block;
  height: 0.26rem;
  line-height: 0.26rem;
  font-size: 0.16rem;
  text-align: center;
}
.fixed-car {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  min-height: .8rem;
  z-index: 99;
  font-size: .16rem;
  display: none;
}
.fixed-car .mygroup-info {
  margin: 0.15rem;
}
.car-options {
  padding: 0 0.15rem;
}
.car-options span {
  float: left;
  width: 0.28rem;
  height: 0.28rem;
}
.car-min {
  background: url("../../images/jian.png") no-repeat left center;
  background-size: 0.28rem 0.28rem;
}
.car-add {
  background: url("../../images/jia.png") no-repeat left center;
  background-size: 0.28rem 0.28rem;
}
.car-options input {
  float: left;
  width: 0.6rem;
  height: 0.26rem;
  margin: 0 0.2rem;
  padding: 0;
  line-height: 0.26rem;
  box-sizing: border-box;
  text-align: center;
}
.confirm-btn {
  display: block;
  height: 0.48rem;
  margin-top: 0.2rem;
  background: #2ac560;
  line-height: 0.48rem;
  color: #fff;
  text-align: center;
  font-size: 0.2rem;
}
.fixed-car-close {
  display: block;
  position: absolute;
  top: 0.15rem;
  right: 0.2rem;
  width: 0.3rem;
  height: 0.3rem;
  background: url("../../images/pop-close.png") no-repeat left center;
  background-size: 0.3rem 0.3rem;
  z-index: 100;
}
.send_price {
  height: 0.56rem;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  line-height: 0.56rem;
  font-size: 0.22rem;
}
.send_price label {
  float: left;
}
.send_price span {
  float: right;
  color: #999;
}
.order-info {
  height: 0.56rem;
  border-bottom: 1px solid #f3f3f3;
  line-height: 0.56rem;
  font-size: 0.24rem;
  text-align: right;
}
.order-info span {
  padding: 0 0.05rem;
  color: #2ac560;
  font-size: 0.26rem;
}
.mygroup-info{margin: 0.2rem 0}
.mygroup-img{float:left; width: .8rem; height: .8rem; margin-right: 0.15rem}
.mygroup-img img{width: 100%; height:100%}
.mygroup-info-r{font-size: 0.16rem; overflow: hidden}
.mygroup-info-r h4{margin-top: 0.05rem; font-size: 0.18rem}
.mygroup-info-list{margin-top: 0.05rem}
.group-purchase-wrap{background:#fff; margin-top: 0.1rem; margin-bottom: .1rem; padding: 0.2rem}
.group-purchase-title h3{height: 0.2rem; border-left:4px solid #ff371e; padding-left: 0.1rem; border-radius: 2px; line-height: 0.2rem; font-size: 0.16rem}
.group-purchase-l{float:left}
.group-purchase-r{float: right}
.onGroup{float:right}
.group-purchase{margin-top: 0.1rem; margin-bottom: 0.1rem}
.group-purchase-list{border-bottom: 1px solid #f3f3f3}
.group-purchase-l img{float:left; width: 0.45rem; height: 0.45rem; border-radius: 50%;}
.group-purchase-l span{float:left; width:0.8rem; height: 0.45rem; line-height: 0.45rem; margin-left: 0.1rem; white-space:nowrap; overflow:hidden; text-overflow: ellipsis;}
.onGroup{float:right; width: 0.6rem; height: 0.32rem; margin-top:.07rem; background:#ff371e; border-radius: .04rem; text-align:center; line-height: .32rem; color:#fff;}
.group-purchase-r{  margin: .03rem .15rem 0 0; text-align: center; line-height: .2rem;}
.group-purchase-r p span{padding: 0 0.04rem; color:#ff371e}
.group-purchase-r p:nth-of-type(2){color:#999; font-size: 0.12rem}
.paddingt{padding-top: 0.1rem}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mask{
  position:absolute;
  left: 0;
  right: 0;
  top:0;
  bottom: 0;
  background:rgba(0,0,0,.3);
  display: none;
  z-index: 99
}
.isShow{display: block;}
.wrapper{position:relative}
.mask2{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background:rgba(0,0,0,.6);
  z-index: 99;
  display: none;
  }
.mask2 img{
  width:3rem;
  position:absolute;
  left: 50%;
  top: 5%;
  margin-left: -1.5rem
}
.mask2Show{
    display: block;
}
</style>
